import React, { useState } from 'react'
import { SimpleForm, SelectInput, ReferenceInput } from 'react-admin'

export const CreateBuildConfigForm = props => {
  const [options, setOptions] = useState([])
  const [buildTypes, setBuildTypes] = useState([])
  const [models, setModels] = useState([])
  const [modelGroups, setModelGroups] = useState([])

  const handleParamChange = async(iri) => {
    setModelGroups([])
    setModels([])
    setBuildTypes([])
    const response = await fetch(`${process.env.REACT_APP_ENTRYPOINT}${iri}`, {
      headers: {
        Authorization: `Bearer ${localStorage['access_token']}`
      }
    })
    const data = await response.json()
    setOptions(data.options)
  }

  const handleOptionChange = async(id) => {
    setModelGroups([])
    setModels([])
    setBuildTypes([])
    const iri = `/api/config_param_options/${id}`
    const response = await fetch(`${process.env.REACT_APP_ENTRYPOINT}${iri}`, {
      headers: {
        Authorization: `Bearer ${localStorage['access_token']}`
      }
    })
    const data = await response.json()
    setModels(await getItems(data.models))
    setModelGroups(await getItems(data.modelGroups))
    setBuildTypes(await getItems(data.buildTypes))
  }

  const getItems = async(arr) => {
    const items = []
    for (let elem of arr) {
      const response = await fetch(`${process.env.REACT_APP_ENTRYPOINT}${elem['@id']}`, {
        headers: {
          Authorization: `Bearer ${localStorage['access_token']}`
        }
      })
      const data = await response.json()
      if (!data.id) {
        data.id = data['@id'].match(/\d+/)[0]
      }
      items.push(data)
    }
    return items
  }

  return (
    <SimpleForm {...props} >
      <ReferenceInput source="company" label="Company" reference="companies" >
        <SelectInput optionName='name' />
      </ReferenceInput>
      <ReferenceInput source="parameter" label="Parameter" reference="config_param_definitions" >
        <SelectInput optionName='name' inputProps={{onChange: e => handleParamChange(e.target.value)}}/>
      </ReferenceInput>
      <SelectInput
        source="selectedOption"
        allowEmpty={!options.length}
        emptyText='Select parameter first'
        choices={options}
        optionText='name'
        inputProps={{onChange: e => handleOptionChange(e.target.value)}}
      />
      {buildTypes && <SelectInput source="buildType" allowEmpty={!buildTypes.length} emptyText='Select option first' choices={buildTypes}/>} 
      {!!models.length && <SelectInput source="model" choices={models}/>}
      {!!modelGroups.length && <SelectInput source="modelGroup" choices={modelGroups} />}
    </SimpleForm>
  )
}