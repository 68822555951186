import React, { useEffect, useState, useCallback } from "react"

import {
  SimpleForm,
  ArrayInput,
  TextInput,
  required,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  BooleanInput,
  ReferenceInput,
  SelectArrayInput,
  usePermissions,
  useDataProvider,
} from "react-admin"

export const ConfigForm = (props) => {
  const { permissions } = usePermissions()
  const { record } = props

  const [models, setModels] = useState()
  const dataProvider = useDataProvider()

  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (record?.company) fetchModels(record.company["@id"])
  }, [record])

  useEffect(() => {
    if (props.match.path === "/configurations/:id") {
      setIsEdit(true)
      return
    }

    setIsEdit(false)
  }, [props.match.path])

  const fetchModels = useCallback(async (value) => {
    const modelsId = await dataProvider.getOne("companies", { id: value })
    const models = await dataProvider.getMany("models", {
      ids: modelsId.data.allowedModels,
    })
    if (models.data.length) {
      setModels(models.data)
    } else {
      setModels([{ name: "Choose company with available models" }])
    }
  }, [])

  const handleCompanyChange = (e) => {
    fetchModels(e.target.value)
  }

  const formatModels = (selected) => {
    const modelIds = models?.map((model) => model.id)
    return selected?.filter((model) => modelIds?.includes(model))
  }

  const feeValidation = (value, allValues) => {
    if (value === null) {
      return "Required"
    }
    if (value < 0) {
      return "Must be over 0"
    }
    return []
  }

  return (
    <SimpleForm {...props}>
      {(permissions?.includes("ROLE_ADMIN") ||
        permissions?.includes("ROLE_SUPER_ADMIN")) && (
        <ReferenceInput
          source="company"
          format={(v) => (v && typeof v !== "string" ? v["@id"] : v)}
          label="Company"
          reference="companies"
          validate={[required()]}
        >
          <SelectInput
            optionText="name"
            optionValue="@id"
            allowEmpty={false}
            inputProps={{ onChange: handleCompanyChange }}
          />
        </ReferenceInput>
      )}
      <TextInput source="name" label="Name" validate={[required()]} />
      <SelectArrayInput
        source="models"
        choices={models}
        format={formatModels}
        optionText="name"
        validate={[required()]}
      />
      <ArrayInput source="pools" label="Pools">
        <SimpleFormIterator>
          <TextInput source="url" label="Url" validate={[required()]} />
          <TextInput source="worker" label="Worker" validate={[required()]} />
          <TextInput
            source="password"
            label="Password"
            validate={[required()]}
          />
          <NumberInput
            source="fee"
            label="Fee"
            inputProps={{
              step: 0.5,
              min: 0.0,
              max: 10000000.0,
            }}
            validate={feeValidation}
          />
          <BooleanInput label="Hidden" source="hidden" />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput label="Hide All Pools" source="hideAllPools" />
    </SimpleForm>
  )
}
