import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    FunctionField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    NumberField,
    ArrayField,
    Datagrid
} from 'react-admin';
import test from '../../assets/test.jpg'


export const ShowCompany = (props) => (
    <Show {...props} title={'Company'}>
        <SimpleShowLayout>
            <TextField source="originId" label="ID" />
            <TextField source="name" label="Name" />
            <TextField source="prefix" label="Prefix" />
            <TextField source="country" label="Country" />
            <FunctionField label="Main Pool" render={r => r.mainPool ? r.mainPool.name : 'Default'} />
            <NumberField source="ourFee" label="fee" />
            <ReferenceArrayField label="Allowed models" reference="models" source="allowedModels">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField label="Allowed builds" reference="build_types" source="allowedBuildTypes">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ArrayField source="feePools" label="Pools">
                <Datagrid>
                    {/* <TextField source="name" label="Name"/> */}
                    <TextField source="ourPool.name" label="Our pool"/>
                    <TextField source="ourPool.host" label="Host"/>
                    <TextField source="worker" label="Worker"/>
                    <TextField source="password" label="Password"/>
                    <NumberField source="fee" label="Fee" />
                </Datagrid>
            </ArrayField>
            <FunctionField
                label="Logo"
                source="logo"
                render={file => {
                    return (
                            <img src={file.logo ? `${process.env.REACT_APP_ENTRYPOINT}/uploads/${file.logo.path}` : test} style={{maxWidth: '250px'}}/>
                    )
                }

                }
            />
        </SimpleShowLayout>
    </Show>
);



