import React, { useCallback, useEffect, useState } from "react";

import {
  SimpleForm,
  TextInput,
  ImageField,
  required,
  NumberInput,
  ImageInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  ReferenceInput,
  SelectInput,
  FunctionField,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  useDataProvider
} from "react-admin";
import test from "../../assets/test.jpg";
import DeleteWithConfirmButton from "../Common/DeleteWithConfirmButton";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    {!window.location.href.includes("/create") && (
      <div>
        <DeleteWithConfirmButton
          undoable={false}
          textConfirm={"Delete company"}
          subTextConfirm={
            "If you delete company, you also delete all users of this company. Are you sure?"
          }
        />
      </div>
    )}
  </Toolbar>
);

export const CompanyForm = (props) => {
  const dataProvider = useDataProvider()
  const [previewPath, setPreviewPath] = useState("");
  const [isEdit, setIsEdit] = useState(false)
  const [feePools, setFeePools] = useState([])
  const [models, setModels] = useState([])
  const [algos, setAlgos] = useState([])
  const [ourPools, setOurPools] = useState([])
  const [disableAdd, setDisableAdd] = useState(false)

  useEffect(() => {
    if (props.match.path === "/companies/:id") {
      setIsEdit(true);
      return;
    }

    setIsEdit(false);
  }, [props.match.path]);

  useEffect(() => {
    getFeePools()
    getModels()
    getOurPools()
  }, [])

  const feeValidation = (value) => {
    if (!value) {
      return "Required";
    }
    if (value < 0) {
      return "Must be over 0";
    }
    return null;
  };

  const handleImageChange = (fileObj) => {
    setPreviewPath(fileObj);
  };

  const fetchItems = async(source) => {
    const response =  await dataProvider.getList(source, {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'id',
        order: 'ASC'
      }
    })
    return response.data
  }

  const getFeePools = async() => {
    const data = await fetchItems('main_pools')
    setFeePools(data)
  }

  const getModels = async() => {
    const data = await fetchItems('models')
    setModels(data)
  }

  const getOurPools = async() => {
    const data = await fetchItems('our_pools')
    setOurPools(data)
  }

  const handleModelsChange = value => {
    const algos = []
    value.forEach(model => {
      const algo = models.find(mdl => mdl['@id'] === model)?.algo
      if (algo) {
        algos.push(algo)
      }
    })
    const set = new Set(algos)
    const res = Array.from(set)
    setAlgos(res)
  }

  const getIRI = (v) => typeof(v) === 'object' ? v['@id'] : v

  return (
    <SimpleForm {...props} redirect={null} toolbar={<CustomToolbar />}>
      <TextInput source="name" label="Name" validate={[required()]} />
      <TextInput source="prefix" label="Prefix" validate={[required()]} />
      <BooleanInput label='Append Suffix to Prefix' source='appendModelToPrefix' />
      <NumberInput
        source="ourFee"
        label="Our fee"
        inputProps={{
          step: 0.5,
          min: 0.0,
          max: 10000000.0,
        }}
        validate={feeValidation}
      />
      <TextInput source="country" label="Country" validate={[required()]} />
      <ReferenceArrayInput
        source="allowedBuildTypes"
        label="Build type"
        reference="build_types"
      >
        <SelectArrayInput
          optionValue="id"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceArrayInput>
      <SelectArrayInput
        source="allowedModels"
        label="Models"
        validate={[required()]}
        choices={models}
        onChange={e => handleModelsChange(e.target.value)}
        format={v => {if (models.length && !algos.length && v) {handleModelsChange(v)}; return v}}
      />
        {algos.map(algo => [
        <ArrayInput label={`SSL Pools (${algo})`} source={`feePools(${algo})`}>
          <SimpleFormIterator>
            <SelectInput
              source={`ourPool`}
              label="name"
              validate={[required()]}
              choices={ourPools.filter(pool => pool.algo === algo)}
              parse={getIRI}
              format={getIRI}
            />
            <TextInput
              source="worker"
              label="Worker"
              validate={[required()]}
            />
            <TextInput
              source="password"
              label="Password"
              validate={[required()]}
            />
            <NumberInput
              source="fee"
              label="Fee"
              inputProps={{
                step: 0.5,
                min: 0.0,
                max: 10000000.0,
              }}
              validate={feeValidation}
            />
          </SimpleFormIterator>
        </ArrayInput>,
        <SelectInput
          source={`mainPoolsByAlgo[${algo}]`}
          label={`Main Pool (${algo})`}
          choices={feePools.filter(pool => pool.algo === algo)}
          allowEmpty
          initialValue={null}
          emptyText="Default"
          emptyValue={null}
          format={(v) => (v && typeof v === "object" ? v["@id"] : v)}
        />
      ])}
      {!previewPath ? (
        <FunctionField
          label="Logo"
          source="logo"
          render={(file) => {
            return (
              <img
                src={
                  file.logo
                    ? `${process.env.REACT_APP_ENTRYPOINT}/uploads/${file.logo.path}`
                    : test
                }
                style={{ maxWidth: "250px" }}
              />
            );
          }}
        />
      ) : null}
      <ImageInput
        source="logo"
        label=""
        accept="image/*"
        onChange={handleImageChange}
      >
        <ImageField source="file" title="title" />
      </ImageInput>
    </SimpleForm>
  );
};
